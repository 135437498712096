<template>
	<div>
		<v-card>
			<v-card-title>
				<v-row>
					<v-col>
						<v-select v-model="tableHeaders" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Colonne visualizzate', {sectionSuffix:transSs})" :items="statistichePersoneFiltroColonne" item-text="key" item-value="value" />
					</v-col>
					<v-col>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							:label="gTranslate('Ricerca libera', {sectionSuffix:transSs})"
							:filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" 
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-title>
			<v-data-table
				dense
				:headers="gTranslateTableHeader(tableHeaders, {sectionSuffix:transSs})"
				:items="value"
				item-key="Id"
				:search="search"
			>
				<template v-slot:item.Immagine="{ item }">
					<v-avatar :size="25">
						<img v-if="item.Immagine" alt="Avatar" :src="imagesPathPerson + item.Immagine + '?width=50&height=50&mode=crop&anchor=top'">
						<avatar v-else :username="item.FullName" :size="25" />
					</v-avatar>
				</template>
				<template v-slot:item.DataDiNascita="{ item }">
					{{ item.DataDiNascita | shortData }}
				</template>
				<template v-slot:item.DistanzaDallaSede="{ item }">
					{{ item.DistanzaDallaSede | kilometri }}
				</template>
				<template v-slot:item.DataInizioLavoro="{ item }">
					{{ item.DataInizioLavoro | shortData }}
				</template>
				<template v-slot:item.DataDiAssunzione="{ item }">
					{{ item.DataDiAssunzione | shortData }}
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
/**********************************************************************************************
**** Componente per la griglia (configurabile) delle persone in statistiche *******************
***********************************************************************************************
***********************************************************************************************
* è una normale girlia di tipo v-table solo che l'elenco delle colonne da visualizzare viene 
* selezionto al volo da uan tendina e caricato da setup. 
* contiene anche il codice necessario per editare e salvare le configurazioni
***********************************************************************************************
*/
// import Vue from 'vue'
// import DebugPanel from '@/components/debug-panel'
// import DebugContainer from '@/components/debug-container'
// import HrDatePicker from '@/components/hr-date-picker'
import moment from 'moment'
import Avatar from 'vue-avatar'
export default {
	name: 'HrPersonaAudits',
	components: { Avatar,  },
	filters: {
		kilometri(dist) {
			return dist > 0 ? dist + ' km' : ''
		},
		notrattino(val) {
			return val === '-' ? '' : val
		},
		shortData: function (date) {
			return date ? moment(date).format('YYYY-MM-DD') : ''
		},
	}, // DebugContainer, DebugPanel, HrDatePicker
	props: {
		// la proprietà bindata direttamente (ovvero legata al v-model) corrisponde alla lista delle persone filtrate da statistiche
		value: {
			type: Array,
			required: true,
			default: () => {return []}
		}
	},
	data: () => {
		return {
			transSs:'.tabPersone',
			dialog: false,
			search:'',
			tableHeaders: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		setup() { return this.$store.getters['setup'] },
		statistichePersoneFiltroColonne() { return JSON.parse( this.setup.StatistichePersoneFiltroColonne) },
		currentUser() { return this.$store.getters['currentUser'] },
		imagesPathPerson() { return this.$store.getters['imagesPathPerson'] },
		
	},
	mounted() {
		this.tableHeaders = this.statistichePersoneFiltroColonne.filter(x => { return x.key=='default' })[0].value
	},
	methods: {
	

	},
}
</script>

<style scoped lang="less">

</style>