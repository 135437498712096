var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense,"label":_vm.gTranslate('Colonne visualizzate', {sectionSuffix:_vm.transSs}),"items":_vm.statistichePersoneFiltroColonne,"item-text":"key","item-value":"value"},model:{value:(_vm.tableHeaders),callback:function ($$v) {_vm.tableHeaders=$$v},expression:"tableHeaders"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.gTranslate('Ricerca libera', {sectionSuffix:_vm.transSs}),"filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.gTranslateTableHeader(_vm.tableHeaders, {sectionSuffix:_vm.transSs}),"items":_vm.value,"item-key":"Id","search":_vm.search},scopedSlots:_vm._u([{key:"item.Immagine",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":25}},[(item.Immagine)?_c('img',{attrs:{"alt":"Avatar","src":_vm.imagesPathPerson + item.Immagine + '?width=50&height=50&mode=crop&anchor=top'}}):_c('avatar',{attrs:{"username":item.FullName,"size":25}})],1)]}},{key:"item.DataDiNascita",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortData")(item.DataDiNascita))+" ")]}},{key:"item.DistanzaDallaSede",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("kilometri")(item.DistanzaDallaSede))+" ")]}},{key:"item.DataInizioLavoro",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortData")(item.DataInizioLavoro))+" ")]}},{key:"item.DataDiAssunzione",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortData")(item.DataDiAssunzione))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }