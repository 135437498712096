<template>
	<standard-container title="Statistiche" icon="mdi-chart-areaspline" show-buttons>
		<template v-slot:buttons>
		</template>

		<!-- header -->
		<template v-slot:header>
			<div class="d-block d-md-flex justify-space-between align-baseline mt-4 mb-8">
				<v-autocomplete v-model="filterSede" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Filtra per sede')" :items="decodifiche.Sedi.filter(sede => { return sede.Visibile })" prepend-inner-icon="mdi-factory" hide-details clear-icon="mdi-close-circle" clearable item-text="DescrizioneSede" item-value="CodiceSede" />
				<v-spacer></v-spacer>
				<v-autocomplete v-model="filterSesso" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Filtra per sesso')" :items="decodificheSessoFiltered" prepend-inner-icon="mdi-human-male-female" hide-details clear-icon="mdi-close-circle" clearable />
				<v-spacer></v-spacer>
				<v-autocomplete v-model="filterPosizione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Filtra per posizione aziendale')" :items="decodifiche.Jobs" prepend-inner-icon="mdi-tools" hide-details clear-icon="mdi-close-circle" clearable item-text="DescrizioneJob" item-value="CodiceJob" />
				<v-spacer></v-spacer>
				<v-autocomplete v-model="filterReparto" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Filtra per reparto o gruppo di lavoro')" :items="decodifiche.RepartiGruppi" prepend-inner-icon="mdi-sitemap" hide-details clear-icon="mdi-close-circle" clearable item-text="DescrizioneRepartoGruppo" item-value="CodiceRepartoGruppo" />
			</div>

			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab key="tabStatistiche">{{ gTranslate('Statistiche') }}</v-tab>
				<v-tab key="tabPersone">{{ gTranslate('Persone') }}</v-tab>
				<v-tab key="tabMappa">{{ gTranslate('Mappa') }}</v-tab>
			</v-tabs>				
		</template>

		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-tabs-items v-model="currentTab">
					<!-- tabStatistiche -->
					<v-tab-item key="tabStatistiche">
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<div class="d-flex flex-row flex-wrap justify-space-between">
									<v-card width="220" min-height="200" :color="darkTheme ? 'red darken-4' : 'red lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Totale persone')"></v-card-title>
										<div style="position:absolute;bottom: 10px;left: 0;right: 0;text-align: center;" class="text-h1">{{ statTotalePersone }}</div>
									</v-card>
									<v-card width="220" min-height="200" :color="darkTheme ? 'deep-orange darken-4' : 'deep-orange lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Età')"></v-card-title>
										<v-card-subtitle v-text="gTranslate('media anni')"></v-card-subtitle>
										<div style="position:absolute;bottom: 10px;left: 0;right: 0;text-align: center;" class="text-h1">{{ statEtaMedia }}</div>
									</v-card>
									<v-card width="220" min-height="200" :color="darkTheme ? 'orange  darken-4' : 'orange  lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Anzianità lavorativa')"></v-card-title>
										<v-card-subtitle v-text="gTranslate('media anni')"></v-card-subtitle>
										<div style="position:absolute;bottom: 10px;left: 0;right: 0;text-align: center;" class="text-h1">{{ statAnziantitaLavorativaMedia }}</div>
									</v-card>
									<v-card width="220" min-height="200" :color="darkTheme ? 'amber  darken-4' : 'amber  lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Anzianità aziendale')"></v-card-title>
										<v-card-subtitle v-text="gTranslate('media anni')"></v-card-subtitle>
										<div style="position:absolute;bottom: 10px;left: 0;right: 0;text-align: center;" class="text-h1">{{ statAnziantitaAziendaleMedia }}</div>
									</v-card>
									<v-card width="220" min-height="200" :color="darkTheme ? 'yellow  darken-4' : 'yellow  lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Distanza da sede')"></v-card-title>
										<v-card-subtitle v-text="gTranslate('media chilometri')"></v-card-subtitle>
										<div style="position:absolute;bottom: 10px;left: 0;right: 0;text-align: center;" class="text-h1">{{ statDistanzaSedeMedia }}</div>
									</v-card>
									<v-card width="390" :color="darkTheme ? 'pink  darken-4' : 'pink  lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Maschi / Femmine')"></v-card-title>
										<v-card-text>
											<apexchart type="donut" :options="statMaschiFemmineOptions" :series="statMaschiFemmineData"></apexchart>
										</v-card-text>
									</v-card>
									<v-card width="410" :color="darkTheme ? 'purple darken-4' : 'purple lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Sedi operative')"></v-card-title>
										<v-card-text>
											<apexchart type="donut" :options="statSediOptions" :series="statSediData"></apexchart>
										</v-card-text>
									</v-card>
									<v-card width="430" :color="darkTheme ? 'light-blue darken-4' : 'light-blue lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Titoli di studio')"></v-card-title>
										<v-card-text>
											<apexchart type="donut" :options="statTitoloStudioOptions" :series="statTitoloStudioData"></apexchart>
										</v-card-text>
									</v-card>
									<v-card width="370" :color="darkTheme ? 'blue  darken-4' : 'blue  lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Lingua inglese')"></v-card-title>
										<v-card-text>
											<apexchart type="donut" :options="statLinguaIngleseOptions" :series="statLinguaIngleseData"></apexchart>
										</v-card-text>
									</v-card>
									<v-card width="220" min-height="200" :color="darkTheme ? 'cyan   darken-4' : 'cyan   lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Potenziale')"></v-card-title>
										<v-card-subtitle v-text="gTranslate('media')"></v-card-subtitle>
										<div v-if="statPotenzialeMedia" style="position:absolute;bottom: 10px;left: 0;right: 0;text-align: center;" class="text-h2">
											<v-rating v-model="statPotenzialeMedia" x-large :length="3" background-color="grey lighten-1"></v-rating>
											{{ statPotenzialeMedia }}
										</div>
									</v-card>
									<v-card width="220" min-height="200" :color="darkTheme ? 'teal  darken-4' : 'teal  lighten-4'" elevation="4" class="mb-4">
										<v-card-title v-text="gTranslate('Engagement')"></v-card-title>
										<v-card-subtitle v-text="gTranslate('media')"></v-card-subtitle>
										<div v-if="statEngagementMedia" style="position:absolute;bottom: 10px;left: 0;right: 0;text-align: center;" class="text-h2">
											<v-rating v-model="statEngagementMedia" x-large :length="3" background-color="grey lighten-1"></v-rating>
											{{ statEngagementMedia }}
										</div>
									</v-card>
								</div>
							</v-col>
						</v-row>
					</v-tab-item>

					<!-- tabPersone -->
					<v-tab-item key="tabPersone">
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<hr-statistiche-persone v-model="personePrefiltered"></hr-statistiche-persone>
							</v-col>
						</v-row>
					</v-tab-item>

					<!-- tabMappa -->
					<v-tab-item key="tabMappa">
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<v-responsive :aspect-ratio="16/9">
									<l-map
										ref="map"
										v-resize="onMapResize"
										style="height: 100%;z-index:0"
										:zoom="mapOptions.zoom"
										:center="mapOptions.center"
										:bounds="mapOptions.bounds"
									>
										<l-tile-layer :url="mapOptions.url" :attribution="mapOptions.attribution"></l-tile-layer>
										<l-marker v-for="(marker, index) in mapOptions.markers" :key="index" :lat-lng="marker.latLng">
											<l-icon :icon-size="[50,50]">
												<v-avatar :size="50" class="elevation-8" style="border: solid 1px gray; background-color:white">
													<img v-if="marker.image" height="50" width="50" :src="imagesPathPerson + marker.image + '?width=100&height=100&mode=crop&anchor=top'">
													<avatar v-else :username="marker.title" :size="50" />
												</v-avatar>												
											</l-icon>
											<l-popup>
												<img v-if="marker.image" height="100" width="100" :src="imagesPathPerson + marker.image + '?width=100&height=100&mode=crop&anchor=top'">
												<v-avatar v-else :size="50" class="ml-auto mr-auto mb-n2" style="display: block">
													<avatar :username="marker.title" :size="50" />
												</v-avatar><br>
												<strong>{{ marker.title }}</strong>
												<div style="max-width:100px;white-space:normal">{{ marker.desc }}</div>
											</l-popup>
										</l-marker>
										<l-marker v-for="(marker, index) in mapOptions.markersSedi" :key="index+'sede'" :lat-lng="marker.latLng">
											<l-icon :icon-size="[50,50]">
												<v-icon :size="50" color="red">mdi-factory</v-icon>
											</l-icon>
											<l-popup>
												<v-icon :size="50" color="red">mdi-factory</v-icon><br>
												<strong>{{ marker.title }}</strong>
												<div style="max-width:100px;white-space:normal">{{ marker.desc }}</div>
											</l-popup>
										</l-marker>
									</l-map>									
								</v-responsive>								
								<debug-panel label="mapOptions.markers">
									{{ mapOptions.markers }}
								</debug-panel>
							</v-col>
						</v-row>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
		
		<template v-slot:footer>
			<v-row>
				<v-col class="d-flex flex-row-reverse">
					<v-btn right x-small class="mt-2" color="green darken-4">
						<json-excel :data="personePrefiltered">{{ gTranslate('excel download') }}</json-excel>
					</v-btn>
				</v-col>
			</v-row>
		</template>

		
		<!-- debug -->
		<template v-slot:debug>
			<v-row>
				<v-col cols="12" md="6">
					<debug-panel label="statEngagementMedia">
						{{ statEngagementMedia }}
					</debug-panel>
				</v-col>
				<v-col cols="12" md="6">
					<debug-panel label="statPotenzialeMedia">
						{{ statPotenzialeMedia }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>		
	</standard-container>
</template>


<script>
/**********************************************************************************************
**** STATISTICHE ***********************************************************************************
***********************************************************************************************
* pagina con lista statistiche disponibili
*/
import StandardContainer from '@/components/standard-container'
import HrStatistichePersone from '@/components/statistiche/hr-statistiche-persone'
import DebugPanel from '@/components/debug-panel'
import Avatar from 'vue-avatar'
import moment from 'moment'
import L from 'leaflet'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import lodash from 'lodash'
import JsonExcel from 'vue-json-excel'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
	name: 'Statistiche',
	components: { StandardContainer, Avatar, DebugPanel, LMap, LTileLayer, LMarker, LPopup, LIcon, JsonExcel, HrStatistichePersone },
	filters: {
		kilometri(dist) {
			return dist > 0 ? dist + ' km' : ''
		},
		notrattino(val) {
			return val === '-' ? '' : val
		},
		shortData: function (date) {
			return date ? moment(date).format('YYYY-MM-DD') : ''
		},
	},
	data: () => {
		return {
			filterSede: '',
			filterSesso: '',
			filterPosizione: '',
			filterReparto: '',
			currentTab: 'tabStatistiche',
			mapOptions: {
				zoom: 8,
				center: L.latLng(45.5, 11.4),
				url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
				attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
				bounds: L.latLngBounds([[45, 11], [46, 10]]),
				markers: [],
				markersSedi: [],
				mapOptions: {
					zoomSnap: 0.5
				},
			},
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		darkTheme() { return this.$vuetify.theme.dark },
		decodifiche() { return this.$store.getters['decodifiche'] },
		imagesPathPerson() { return this.$store.getters['imagesPathPerson'] },
		persone() { return this.$store.getters['persone'] },
		decodificheSessoFiltered() {
			return this.decodifiche.Sesso.filter(item => { return item !== ''})
		},
		filteredBySede() { return !!this.filterSede },
		filteredBySesso() { return !!this.filterSesso },
		filteredByPosizione() { return !!this.filterPosizione },
		filteredByReparto() { return !!this.filterReparto },

		personePrefiltered() {
			return this.persone.filter((item) => {
				var filtroVisibilita = item.Visibile
				var filtroSede = true
				var filtroSesso = true
				var filtroPosizione = true
				var filtroReparto = true
				if(this.filteredBySede) {
					filtroSede = item.SediOperativeList.some(dummy => { return dummy === this.filterSede })
				}
				if(this.filteredBySesso) {
					filtroSesso = item.Sesso === this.filterSesso
				}
				if(this.filteredByPosizione) {
					filtroPosizione = item.JobsList.some(dummy => { return dummy === this.filterPosizione })
				}
				if(this.filteredByReparto) {
					filtroReparto = item.RepartoGruppoList.some(dummy => { return dummy === this.filterReparto })
				}
				return filtroVisibilita && filtroSede && filtroPosizione && filtroReparto && filtroSesso
			})
		},
		// statistiche base
		statTotalePersone() { return this.personePrefiltered.length }, 
		statTotaleMaschi() { return this.personePrefiltered.filter(item => {return item.Sesso === 'M'}).length }, 
		statTotaleFemmine() { return this.personePrefiltered.filter(item => {return item.Sesso === 'F'}).length }, 
		statEtaMedia() { 
			let numPersone = this.personePrefiltered.filter(item => {return item.Eta > 0}).length
			let somma = this.personePrefiltered.filter(item => {return item.Eta > 0}).reduce((total, item) => {  return total + item.Eta}, 0.0)
			return numPersone > 0 ? (somma / numPersone).toFixed(0) : null
		}, 
		statAnziantitaLavorativaMedia() { 
			let numPersone = this.personePrefiltered.filter(item => {return item.AnzianitaLavorativaYear > 0}).length
			let somma = this.personePrefiltered.filter(item => {return item.AnzianitaLavorativaYear > 0}).reduce((total, item) => {  return total + item.AnzianitaLavorativaYear}, 0.0)
			return numPersone > 0 ? (somma / numPersone).toFixed(0) : null
		},
		statAnziantitaAziendaleMedia() { 
			let numPersone = this.personePrefiltered.filter(item => {return item.AnzianitaAziendaleYear > 0}).length
			let somma = this.personePrefiltered.filter(item => {return item.AnzianitaAziendaleYear > 0}).reduce((total, item) => {  return total + item.AnzianitaAziendaleYear}, 0.0)
			return numPersone > 0 ? (somma / numPersone).toFixed(0) : null
		},
		statDistanzaSedeMedia() {
			let numPersone = this.personePrefiltered.filter(item => {return item.DistanzaDallaSede > 0}).length
			let somma = this.personePrefiltered.filter(item => {return item.DistanzaDallaSede > 0}).reduce((total, item) => {  return total + item.DistanzaDallaSede}, 0.0)
			return numPersone > 0 ? (somma / numPersone).toFixed(0) : null
		},
		statPotenzialeMedia() {
			let numPersone = this.personePrefiltered.filter(item => {return item.ValutazioneSulPotenziale > 0}).length
			let somma = this.personePrefiltered.filter(item => {return item.ValutazioneSulPotenziale > 0}).reduce((total, item) => {  return total + item.ValutazioneSulPotenziale}, 0.0)
			return numPersone > 0 ? Number((somma / numPersone).toFixed(0)) : null
		},
		statEngagementMedia() {
			let numPersone = this.personePrefiltered.filter(item => {return item.LivelloEngagement > 0}).length
			let somma = this.personePrefiltered.filter(item => {return item.LivelloEngagement > 0}).reduce((total, item) => {  return total + item.LivelloEngagement}, 0.0)
			return numPersone > 0 ? Number((somma / numPersone).toFixed(0)) : null
		},
		// chart globla options
		statCommonOptions() {
			return {
				legend: {
					labels:{
						colors:[this.darkTheme? 'white' : 'black']
					}
				},
				fill: {
					// type: 'gradient',
				},
			}
		},
		// donut maschni femmine
		statMaschiFemmineOptions() {
			return lodash.merge(JSON.parse(JSON.stringify(this.statCommonOptions)), {
				labels: ['Maschi', 'Femmine'],
			})
		},
		statMaschiFemmineData() {
			return [this.statTotaleMaschi, this.statTotaleFemmine]
		},
		// donut titolo studio
		statTitoloStudioOptions() {
			return lodash.merge(JSON.parse(JSON.stringify(this.statCommonOptions)), {
				labels: this.decodifiche.TitoliDiStudio.map(item => {return item.value}).filter(item => {return item}),
			})
		},
		statTitoloStudioData() {
			var result = []
			// array degli id titolistudio
			let codici = this.decodifiche.TitoliDiStudio.map(item => {return item.key}).filter(item => {return item>0})
			codici.forEach((codice) => {
				// per ogni codicesede conta le persone che lo hanno nella sottolista delle sedioperative
				result.push(this.personePrefiltered.filter(item => {return item.TitoloStudio === codice}).length)
			})
			return result
		},
		// donut sedi
		statSediOptions() {
			return lodash.merge(JSON.parse(JSON.stringify(this.statCommonOptions)), {
				// array dei nomi sede
				labels: this.decodifiche.Sedi.filter(sede => { return sede.Visibile }).map(item => {return item.DescrizioneSede}).filter(item => {return item}),
			})
		},
		statSediData() {
			var result = []
			// array dei codici sede disponibili
			let codiciSedi = this.decodifiche.Sedi.filter(sede => { return sede.Visibile }).filter(sede => { return sede.Visibile }).map(item => {return item.CodiceSede})
			codiciSedi.forEach((codiceSede) => {
				// per ogni codicesede conta le persone che lo hanno nella sottolista delle sedioperative
				result.push(this.personePrefiltered.filter(item => {return item.SediOperativeList.some(dummy => { return dummy === codiceSede })}).length)
			})
			return result
		},
		// donut lingua inglese
		statLinguaIngleseOptions() {
			return lodash.merge(JSON.parse(JSON.stringify(this.statCommonOptions)), {
				// array conoscenza lingua
				labels: this.decodifiche.LivelloConoscenzaInglese.map(item => {return item.value}).filter(item => {return item}),
			})
		},
		statLinguaIngleseData() {
			var result = []
			// array dei codici sede disponibili
			let codici = this.decodifiche.LivelloConoscenzaInglese.map(item => {return item.key}).filter(item => {return item>0})
			codici.forEach((codice) => {
				// per ogni codicesede conta le persone che lo hanno nella sottolista delle sedioperative
				result.push(this.personePrefiltered.filter(item => {return item.LivelloConoscenzaInglese === codice}).length)
			})
			return result
		},
	},
	watch:{
		/* (Bug #1057)
		$route (to, from){
			// console.warn('watch $route')
			this.mapLoadMarkersAndSetBounds()
		},
		*/
		personePrefiltered() {
			// console.warn('watch personePrefiltered')
			this.mapLoadMarkersAndSetBounds()
		},
	},	
	mounted() {
		this.mapLoadMarkersAndSetBounds()
	},
	methods: {
		// mappa resixe
		onMapResize() {
			this.$refs.map.mapObject.invalidateSize()
		},
		// mappa, carica la struttura dei markers per leaflet ed effettua l'auto bonding
		mapLoadMarkersAndSetBounds() {
			// console.warn('mapLoadMarkersAndSetBounds')
			var markersObjDummy = []
			var markers = []
			this.mapOptions.markersSedi = []
			this.personePrefiltered.forEach(persona => {
				// v-if="item.coordinate" :position="{lat: item.coordinate._lat, lng: item.coordinate._long}"/>
				if (persona.IndirizzoLat && persona.IndirizzoLon) {
					var marker = {
						title: persona.FullName,
						desc: persona.Indirizzo,
						image: persona.Immagine,
						latLng: latLng(persona.IndirizzoLat, persona.IndirizzoLon)
					}
					markers.push(marker)
					markersObjDummy.push(
						L.marker([persona.IndirizzoLat, persona.IndirizzoLon])
					)
				}
			})
			// aggiunge le sedi (sia in una struttura per la visualizzazione sulla mappa che in markersObjDummy per il calcolo del bounds)
			this.decodifiche.Sedi.filter(sede => { 
				let filtroSede = true
				if (this.filteredBySede) {
					filtroSede = sede.CodiceSede === this.filterSede
				}
				var dummy = filtroSede && sede.Visibile && sede.IndirizzoLat!==0 && sede.IndirizzoLon!==0 
				// console.warn('filter, sede', dummy, filtroSede, sede, sede.Visibile, sede.IndirizzoLat, sede.IndirizzoLon)
				return dummy
			}).forEach(sede => {
				

				var markerSede = {
					title: sede.DescrizioneSede,
					desc: sede.Indirizzo,
					latLng: latLng(sede.IndirizzoLat, sede.IndirizzoLon)
				}
				this.mapOptions.markersSedi.push(markerSede)
				markersObjDummy.push(
					L.marker([sede.IndirizzoLat, sede.IndirizzoLon])
				)
			})
			//
			var group =  L.featureGroup(markersObjDummy)
			this.$nextTick(() => {
				if(markers.length>0) 
					this.mapOptions.bounds = group.getBounds().pad(0.1)
				this.mapOptions.markers = markers
			})
		},
	},
}
</script>

<style scoped lang="less">

</style>